import { CheckCircleTwoTone, CopyOutlined } from "@ant-design/icons";
import { Result } from "antd";
import { useNavigate } from "react-router-dom";
import { copyText } from "../../plugins/common";
import styles from "./style.module.scss";
import "./style.scss";

const Step2 = ({ orderData }) => {
  const navigate = useNavigate();
  return (
    <Result
      style={{ padding: "40px 12px" }}
      status="success"
      icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
      title="提交成功"
      subTitle={
        <div>
          <div>
            訂單網址 :{" "}
            <span
              className={styles.orderUrl}
              onClick={(e) => copyText(orderData.shortUrl)}
            >
              {orderData.shortUrl}
              <CopyOutlined />
            </span>
          </div>
          <div>
            訂單編號 :{" "}
            <span
              className={styles.orderNumber}
              onClick={(e) => copyText(orderData.packId)}
            >
              {orderData.packId}
              <CopyOutlined />
            </span>
          </div>
        </div>
      }
      extra={
        <div
          className={styles.btn}
          onClick={() => {
            navigate(`/order/${orderData.packId}`);
            window.location.reload();
          }}
        >
          查看訂單
        </div>
      }
    />
  );
};

export default Step2;
