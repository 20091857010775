export const energyList = [
  { img: "/images/colorless.png", name: "colorless", show_name: "無" },
  { img: "/images/darkness.png", name: "darkness", show_name: "闇" },
  { img: "/images/dragon.png", name: "dragon", show_name: "龍" },
  { img: "/images/fairy.png", name: "fairy", show_name: "妖" },
  { img: "/images/fighting.png", name: "fighting", show_name: "鬥" },
  { img: "/images/fire.png", name: "fire", show_name: "火" },
  { img: "/images/grass.png", name: "grass", show_name: "草" },
  { img: "/images/lightning.png", name: "lightning", show_name: "雷" },
  { img: "/images/metal.png", name: "metal", show_name: "鋼" },
  { img: "/images/psychic.png", name: "psychic", show_name: "超" },
  { img: "/images/water.png", name: "water", show_name: "水" },
];
export const energyItems = [];
energyList.map((item) =>
  energyItems.push({
    displayName: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={item.img}
          alt={`${item.name}`}
          style={{ width: "20px", height: "20px", marginRight: "8px" }}
        />
        <div>{item.show_name}</div>
      </div>
    ),
    show_name: item.show_name,
    value: item.name,
    name: "energy",
  })
);
