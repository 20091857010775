import { message } from "antd";
import { Dialog } from "antd-mobile";
import axios from "axios";
import localStorageKey from "../enumerations/localStorageKey";
import { token, userData } from "../plugins/localStorage";
import { logout } from "../plugins/logout";

export const httpClient = axios.create({
  baseURL: "/api/",
});

httpClient.interceptors.request.use(
  (config) => {
    //發送request前 判斷vuex中是否存在token
    //若存在則統一在http請求的header都加上token
    if (config.data?.needAuth || config.params?.needAuth) {
      const token = localStorage.getItem(localStorageKey.TOKEN);
      token && (config.headers.Authorization = "Bearer " + token);
      return config;
    } else {
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
//response攔截器
httpClient.interceptors.response.use((response) => {
  const code = response.data.code;
  switch (code) {
    case 401:
      message.error("登入逾時，請重新登入");
      logout();
      break;
    case 1001:
      Dialog.alert({
        title: "提示",
        content: "此訂單不存在",
        closeOnMaskClick: true,
        onClose: () => {
          if (!token || !userData()) {
            window.location.pathname = "/login";
          } else {
            window.location.pathname = "/order";
          }
        },
      });
      break;
    case 1002:
      message.error("缺少必填欄位");
      break;
    case 1004:
      message.error("帳號或密碼輸入錯誤");
      break;
    case 1006:
      message.error("帳號或密碼輸入錯誤");
      break;
    case 1007:
      message.error("帳號已被使用");
      break;
    default:
      return response;
  }
});
export default function req(method, url, data = null, needAuth = false) {
  method = method.toLowerCase();

  if (method === "post") {
    return httpClient.post(url, data);
  } else if (method === "get") {
    return httpClient.get(url, { params: data });
  } else if (method === "delete") {
    return httpClient.delete(url, { params: data });
  } else if (method === "put") {
    return httpClient.put(url, data);
  } else {
    console.error("未知的method" + method);
    return false;
  }
}
