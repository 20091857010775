import clsx from "clsx";
import styles from "./style.module.scss";

const PreviewCard = ({ showPrev, setShowPrev, src }) => {
  return (
    <div className={clsx(styles.previewCard, { [styles.show]: showPrev })}>
      <div className={styles.container} onClick={() => setShowPrev(false)}>
        <img className={styles.card} src={src} alt="card" />
      </div>
    </div>
  );
};

export default PreviewCard;
