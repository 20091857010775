import { GoogleOAuthProvider } from "@react-oauth/google";
import { ConfigProvider } from "antd";
import { useEffect } from "react";
import "./App.css";
import FontSetting from "./component/FontSetting";
import { GOOGLE_CLIENT_ID } from "./config";
import Context from "./context";
import { useLogin } from "./context/LoginContext";
import "./plugins/i18n";
import RouterRender from "./router";

function App() {
  // const navigate = useNavigate();
  const { isLogin } = useLogin();
  const theme = require(`./theme/theme.json`);
  const setTheme = () => {
    const root = document.querySelector(":root");
    const finalTheme = Object.entries(theme);
    finalTheme.map((color) => {
      root.style.setProperty(color[0], color[1]);
      return null;
    });
  };
  useEffect(() => {
    setTheme();
  }, [theme]);

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          components: {
            Steps: {
              iconSizeSM: 20,
            },
          },
        }}
      >
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <Context>
            <FontSetting />
            <RouterRender />
          </Context>
        </GoogleOAuthProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
