import {
  CloseOutlined,
  LockOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { LoginForm, ProFormText } from "@ant-design/pro-components";
import { Form, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useSignUp } from "./hooks/index.hooks";
import styles from "./style.module.scss";
import "./style.scss";

const SignUp = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate: signUp } = useSignUp();

  const handleSubmit = async () => {
    const params = form.getFieldsValue();
    signUp(
      { ...params, channel: "Local" },
      {
        onSuccess: (data) => {
          message.success("註冊成功");
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  };
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.close} onClick={() => navigate("/")}>
          <CloseOutlined style={{ color: "var(--white)" }} />
        </div>
        <img
          className={styles.logo}
          src={`/images/login_logo.png`}
          alt="logo"
        />
      </div>
      <div className={`${styles.bottomContainer} bottom-signup-container`}>
        <LoginForm
          form={form}
          title="註冊"
          subTitle=" "
          submitter={{
            searchConfig: {
              submitText: "註冊",
            },
            submitButtonProps: {
              style: {
                width: "100%",
              },
            },
          }}
          onFinish={handleSubmit}
        >
          <>
            <ProFormText
              name="name"
              fieldProps={{
                size: "large",
                prefix: <UserOutlined className={"prefixIcon"} />,
              }}
              placeholder={"請輸入帳號"}
              rules={[
                {
                  required: true,
                  message: "帳號為必填",
                },
              ]}
            />
            <ProFormText.Password
              name="password"
              fieldProps={{
                size: "large",
                prefix: <LockOutlined className={"prefixIcon"} />,
              }}
              placeholder={"請輸入6~12位密碼"}
              rules={[
                {
                  required: true,
                  message: "密碼為必填",
                },
                {
                  min: 6,
                  max: 12,
                  message: "請輸入6~12位密碼",
                },
              ]}
            />
            <ProFormText
              name="email"
              fieldProps={{
                size: "large",
                prefix: <MailOutlined className={"prefixIcon"} />,
              }}
              placeholder={"請輸入email"}
              rules={[
                {
                  type: "email",
                  message: "email格式錯誤",
                },
              ]}
            />
          </>
          <div
            style={{
              marginBlockEnd: 24,
            }}
          >
            <div
              style={{
                float: "right",
                color: "var(--brand6)",
              }}
              onClick={() => navigate("/login")}
            >
              返回登入
            </div>
          </div>
        </LoginForm>
      </div>
    </div>
  );
};

export default SignUp;
