import { useMutation } from "@tanstack/react-query";
import { apiRequestGoogleLogin, apiRequestLogin } from "../service";

export const useLogin = () => {
  return useMutation({
    mutationFn: (params) => apiRequestLogin(params).then((res) => res.data),
  });
};

export const useGoogle = () => {
  return useMutation({
    mutationFn: (params) =>
      apiRequestGoogleLogin(params).then((res) => res.data),
  });
};
