import Home from "../pages/Home";
import Login from "../pages/Login";
import Order from "../pages/Order";
import OrderDetail from "../pages/OrderDetail";
import SignUp from "../pages/SignUp";
// import Test from "../pages/Test";

const RouterList = [
  {
    path: "/",
    name: "home",
    component: Home,
    navType: {
      hideHeader: false,
      hideFooter: false,
    },
  },
  {
    path: "/order",
    name: "order",
    component: Order,
    navType: {
      hideHeader: false,
      hideFooter: false,
    },
  },
  {
    path: "/order/:id",
    name: "orderDetail",
    component: OrderDetail,
    navType: {
      hideHeader: false,
      hideFooter: false,
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUp,
    navType: {
      hideHeader: false,
      hideFooter: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    navType: {
      hideHeader: false,
      hideFooter: false,
    },
  },
];
export default RouterList;
