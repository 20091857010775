import React, { useState } from "react";
import { useGetCardSeries } from "../../hooks/cardSeries/cardSeries.hooks.js";
import { CardSelectArea, FilterDrawer, Header, Search } from "./component";
import CardList from "./component/CardList/index.jsx";
import styles from "./style.module.scss";

const Home = () => {
  const [searchValue, setSearchValue] = useState({
    card_name: "",
    card_type: [],
    energy_type: [],
    galleries_id: [],
    game_mark: [],
  });
  const [localSearchValue, setLocalSearchValue] = useState(searchValue);
  const [cardSelect, setCardSelect] = useState([]);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [openHotRender, setOpenHotRender] = useState(false);
  const { data: galleriesItems } = useGetCardSeries();

  const handleRemoveCard = (id) => {
    return setCardSelect((prev) => prev.filter((card) => card.card_id !== id));
  };
  return (
    <div className={styles.home}>
      <Header />
      <Search
        setSearchValue={setSearchValue}
        localSearchValue={localSearchValue}
        setLocalSearchValue={setLocalSearchValue}
        openHotRender={openHotRender}
        setOpenHotRender={setOpenHotRender}
        setOpenFilterDrawer={setOpenFilterDrawer}
      />
      <CardList
        searchValue={searchValue}
        cardSelect={cardSelect}
        setCardSelect={setCardSelect}
        handleRemoveCard={handleRemoveCard}
        openFilterDrawer={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
        openHotRender={openHotRender}
      />
      <CardSelectArea
        cardSelect={cardSelect}
        setCardSelect={setCardSelect}
        handleRemoveCard={handleRemoveCard}
        galleriesItems={galleriesItems}
      />
      <FilterDrawer
        openFilterDrawer={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
        setSearchValue={setSearchValue}
        localSearchValue={localSearchValue}
        setLocalSearchValue={setLocalSearchValue}
        galleriesItems={galleriesItems}
      />
    </div>
  );
};

export default Home;
