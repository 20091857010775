import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import { Drawer, Form, Steps } from "antd";
import { useState } from "react";
import { usePackOrder } from "../../pages/Home/hooks/index.hooks";
import { userData } from "../../plugins/localStorage";
import Step0 from "../Step0";
import Step1 from "../Step1";
import Step2 from "../Step2";
import styles from "./style.module.scss";
import "./style.scss";

const OrderDrawer = ({
  openOrderDrawer,
  setOpenOrderDrawer,
  items,
  galleriesItems,
  _orderData,
}) => {
  const [step, setStep] = useState(0);
  const [fields, setFields] = useState({});
  const [orderData, setOrderData] = useState("");
  const [form] = Form.useForm();
  const { mutate: packOrder } = usePackOrder();
  const stepItems = [
    {
      title: "編輯",
    },
    {
      title: "確認",
    },
    {
      title: "完成",
    },
  ];
  const onFinish = () => {
    const findCardId = () => {
      const cardId = [];
      Object.entries(fields).map((field) => {
        if (field[0].includes("price_")) {
          cardId.push(field[0].split("_")[1]);
          return cardId;
        }
        return cardId;
      });
      return cardId;
    };
    const findContent = (cardId) => {
      const content = [];
      cardId.map((id) => {
        let cardData = { cardId: id };
        Object.entries(fields).map((field) => {
          switch (field[0]) {
            case `quota_${id}`:
              return (cardData = { ...cardData, quota: field[1].toString() });
            case `price_${id}`:
              return (cardData = { ...cardData, amount: field[1].toString() });
            case `comment_${id}`:
              return (cardData = { ...cardData, comment: field[1] });
            default:
              return content;
          }
        });
        content.push(cardData);
        return content;
      });
      return content;
    };
    const findTotalAmount = (content) => {
      let totalAmount = 0;
      content.map((item) => {
        totalAmount += item.amount * item.quota;
        return totalAmount;
      });
      return totalAmount.toString();
    };
    const cardId = findCardId();
    const content = findContent(cardId);
    const totalAmount = findTotalAmount(content);
    packOrder(
      {
        userId: userData().userId,
        type: fields.type === 0 ? "buy" : "sell",
        pack_amount: totalAmount,
        pack_comment: fields.comment,
        content,
      },
      {
        onSuccess: (data) => {
          setStep(2);
          setOrderData(data);
        },
        onError: (error) => {
          console.log("pack order", error);
        },
      }
    );
  };

  const handleClickNext = async () => {
    if (step === 1) {
      onFinish();
      return;
    }
    setStep((prev) => prev + 1);
    setFields(form?.getFieldsValue());
  };
  const handleClose = () => {
    setOpenOrderDrawer(false);
    form?.resetFields();
    setStep(0);
  };
  return (
    <Drawer
      className="order-drawer"
      title="成立訂單"
      width="100%"
      onClose={() => setStep((prev) => prev - 1)}
      open={openOrderDrawer}
      extra={<CloseOutlined onClick={handleClose} />}
      closeIcon={
        step === 1 ? (
          <LeftOutlined style={{ color: "var(--text5)", fontSize: "16px" }} />
        ) : (
          <div style={{ width: "16px" }}></div>
        )
      }
    >
      <div className={styles.container}>
        <Steps
          current={step}
          size="small"
          labelPlacement="vertical"
          items={stepItems}
          responsive={false}
        />
        <Form
          form={form}
          name="order"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          {step === 0 ? (
            <Step0
              items={items}
              galleriesItems={galleriesItems}
              form={form}
              orderData={_orderData}
            />
          ) : step === 1 ? (
            <Step1 items={items} fieldsValue={fields} />
          ) : (
            <Step2 orderData={orderData} />
          )}
        </Form>
        {step === 0 || step === 1 ? (
          <div className={styles.submitBtn} onClick={handleClickNext}>
            <div className={styles.btnText}>
              {step === 0 ? "下一步" : "確認，成立訂單"}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </Drawer>
  );
};

export default OrderDrawer;
