import { ErrorBlock, List } from "antd-mobile";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { findTotalQuota } from "../../../../plugins/common";
import { useGetOrderList } from "../../hooks/index.hooks";
import styles from "./style.module.scss";
import "./style.scss";

const OrderList = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const { data, isSuccess } = useGetOrderList();
  useEffect(() => {
    if (isSuccess) {
      const content = data.content;
      if (!content) {
        setItems([]);
        return;
      }
      let _items = [];
      content.map((perOrder) => {
        _items.push({
          pack_id: perOrder.pack_id,
          type: perOrder.type,
          quota: findTotalQuota(perOrder),
          images: perOrder.ptcg_pack_detail.map(
            (perCard) => perCard.ptcg_card.image
          ),
        });
        return _items;
      });
      setItems(_items);
    }
  }, [isSuccess, data, navigate]);
  return (
    <div className="order-list">
      <List>
        {items.map((item, index) => {
          return (
            <List.Item
              onClick={() => navigate(`/order/${item.pack_id}`)}
              key={index}
            >
              <div className={styles.order}>
                <div className={styles.cardList}>
                  {item.images?.map((img, index) => {
                    if (index < 3)
                      return (
                        <div className={styles.card} key={index}>
                          <img className={styles.cardImg} src={img} alt={img} />
                        </div>
                      );
                  })}
                </div>
                <div className={styles.data}>
                  <div className={styles.orderNumber}>#{item.pack_id}</div>
                  <div className={styles.buySell}>
                    <img
                      className={styles.buySellImg}
                      src={
                        item?.type === "buy"
                          ? "/images/buy.png"
                          : "/images/sell.png"
                      }
                      alt={data?.content?.type}
                    />
                  </div>
                  <div className={styles.cardQuota}>
                    <img
                      className={styles.quotaImg}
                      src={`/images/card_quota.png`}
                      alt="quotaImg"
                    />
                    <div className={styles.quotaValue}>{item.quota}</div>
                  </div>
                </div>
              </div>
            </List.Item>
          );
        })}
      </List>
      {items.length === 0 && (
        <ErrorBlock status="empty" title="目前尚未有訂單" description={null} />
      )}
    </div>
  );
};

export default OrderList;
