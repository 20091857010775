import { Divider } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createFileName, useScreenshot } from "use-react-screenshot";
import { CardDetail, Header, OrderDrawer } from "../../component";
import { useGetCardSeries } from "../../hooks/cardSeries/cardSeries.hooks";
import { useGetOrder } from "../OrderDetail/hooks/index.hooks";
import ActionArea from "./component/ActionArea";
import styles from "./style.module.scss";
import "./style.scss";

const OrderDetail = () => {
  const [items, setItems] = useState([]);
  const [openOrderDrawer, setOpenOrderDrawer] = useState(false);
  const navigate = useNavigate();
  const orderId = window.location.pathname.split("/")[2];
  const { data: orderData, isSuccess } = useGetOrder({ orderId });
  const { data: galleriesItems } = useGetCardSeries();

  const ref = useRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const download = (image, { name = "photo", extension = "png" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };
  const getImage = () => takeScreenshot(ref.current).then(download);

  const findValue = (title, item) => {
    switch (title) {
      case "quota":
        return item.quota;
      case "price":
        return item.amount;
      case "comment":
        return item.comment;
      default:
        return "";
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const content = orderData.content;
      let _items = [];
      content.ptcg_pack_detail.map((item) =>
        _items.push({
          ...item.ptcg_card,
          quota: item.quota,
          amount: item.amount,
          comment: item.comment,
        })
      );
      setItems(_items);
    }
  }, [isSuccess, orderData, navigate]);
  return (
    <div className={styles.orderDetail} ref={ref}>
      <Header
        title={`#${orderId}`}
        extra={true}
        toPage={"/order"}
        orderId={orderId}
        orderData={orderData?.content}
        setOpenOrderDrawer={setOpenOrderDrawer}
      />
      <Divider style={{ margin: 0 }} />
      {/* <img style={{ width: "200px" }} src={image} alt={"ScreenShot"} /> */}
      <div className={styles.cardDetailContainer}>
        <CardDetail
          orderData={orderData?.content}
          showTotalArea={true}
          items={items}
          findValue={findValue}
        />
      </div>
      <ActionArea getImage={getImage} />
      <OrderDrawer
        openOrderDrawer={openOrderDrawer}
        setOpenOrderDrawer={setOpenOrderDrawer}
        items={items}
        galleriesItems={galleriesItems}
        _orderData={orderData}
      />
    </div>
  );
};

export default OrderDetail;
