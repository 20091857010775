import { Spin } from "antd";
import { Dialog, ErrorBlock, Footer } from "antd-mobile";
import { useEffect, useRef, useState } from "react";
import "react-photo-view/dist/react-photo-view.css";
import { ListSkeleton } from "../../../../component";
import { useLazyLoad } from "../../../../hooks/lazyLoad/lazyLoad.hooks";
import { useGetCardSearch } from "../../hooks/index.hooks";
import PreviewCard from "../PreviewCard";
import styles from "./style.module.scss";

const CardList = ({
  searchValue,
  cardSelect,
  setCardSelect,
  handleRemoveCard,
  openFilterDrawer,
  setOpenFilterDrawer,
  openHotRender,
}) => {
  const [showPrev, setShowPrev] = useState(false);
  const [cardSrc, setCardSrc] = useState(null);
  const [cardSearchData, setCardSearchData] = useState(null);

  const observer = useRef(null);
  const {
    data: cardSearch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch: refetchCardSearch,
    isLoading: isCardSearchLoading,
    isError: isCardSearchError,
  } = useGetCardSearch(
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages?.length < lastPage?.last_page) {
          return pages?.length + 1;
        } else {
          return undefined;
        }
      },
      enabled: !openFilterDrawer && !openHotRender,
    },
    {
      card_name: searchValue.card_name,
      card_type: searchValue.card_type.join(","),
      energy_type: searchValue.energy_type.join(","),
      galleries_id: searchValue.galleries_id.join(","),
      game_mark: searchValue.game_mark.join(","),
    }
  );
  const lazyLoad = useLazyLoad({
    triggerRef: observer,
    hasNextPage: hasNextPage,
    fetchNextPage: fetchNextPage,
    isFetching: isFetchingNextPage,
  });

  const handleClickPreview = (e, src) => {
    e.stopPropagation();
    setShowPrev(true);
    setCardSrc(src);
  };
  const handleAddCard = (card) => {
    if (isSelected(card.card_id)) return;
    setCardSelect((prev) => [...prev, card]);
  };
  const isSelected = (id) => {
    let selectedId = [];
    cardSelect.map((card) => selectedId.push(card.card_id));
    return selectedId.includes(id);
  };

  useEffect(() => {
    if (cardSearch) {
      setCardSearchData(cardSearch);
    }
  }, [cardSearch]);
  return (
    <>
      <div className={styles.cardList}>
        <div className={styles.header}>
          <div className={styles.result}>
            <span style={{ color: "var(--text5)", fontWeight: "500" }}>
              {searchValue?.card_name
                ? `"${searchValue?.card_name.trim()}"`
                : "全部"}
            </span>
            搜尋結果({cardSearchData?.pages[0]?.total || 0})
          </div>
          <div
            className={styles.sort}
            onClick={() => {
              Dialog.alert({
                title: "提示",
                content: "近期更新",
                closeOnMaskClick: true,
              });
            }}
          >
            <img
              className={styles.sortImg}
              src={`/images/sort.png`}
              alt="sort"
            />
            <span className={styles.sortText}>排序</span>
          </div>
        </div>
        {isCardSearchLoading ? (
          <ListSkeleton containerStyle={{ padding: "16px 0" }} />
        ) : (
          <div className={styles.cardArea}>
            {cardSearchData?.pages?.map((perPageData, pageIndex) => {
              return perPageData?.data?.map((card, index) => {
                return (
                  <div
                    className={styles.card}
                    key={index}
                    onClick={() => {
                      isSelected(card.card_id)
                        ? handleRemoveCard(card.card_id)
                        : handleAddCard(card);
                    }}
                  >
                    <div className={styles.cardImgArea}>
                      <img
                        className={styles.cardImg}
                        src={card.image}
                        alt={card.card_name}
                      />
                      <div
                        className={`${styles.cardMask} ${
                          isSelected(card.card_id) && styles.selected
                        }`}
                      >
                        <span className={styles.cardMaskText}>已選擇</span>
                      </div>
                    </div>
                    <img
                      className={styles.magnifier}
                      src={`/images/magnifier.png`}
                      alt="magnifier"
                      onClick={(e) => handleClickPreview(e, card.image)}
                    />
                  </div>
                );
              });
            })}
          </div>
        )}
        {!cardSearchData?.pages[0] && <ErrorBlock status="empty" />}
        {cardSearchData?.pages[0] && !hasNextPage && (
          <Footer
            label="没有更多了"
            style={{ backgroundColor: "var(--homeBg)" }}
          ></Footer>
        )}
        <div
          ref={observer}
          className={styles.loadingContainer}
          style={{ display: hasNextPage ? "flex" : "none" }}
        >
          <Spin />
        </div>
      </div>
      <PreviewCard
        showPrev={showPrev}
        setShowPrev={setShowPrev}
        src={cardSrc}
      />
    </>
  );
};

export default CardList;
