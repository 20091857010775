import { BasicDrawer } from "../../../../component";

const InnerDrawer = ({
  openDrawer,
  setOpenDrawer,
  onCheck,
  drawerItems,
  localSearchValue,
  title,
}) => {
  const onClose = () => {
    setOpenDrawer(false);
  };

  return (
    <BasicDrawer
      open={openDrawer}
      title={title}
      extra={<></>}
      items={drawerItems}
      onClose={onClose}
      rightContainer="checkbox"
      onCheck={onCheck}
      searchValue={localSearchValue}
    />
  );
};

export default InnerDrawer;
