import { Dialog } from "antd-mobile";
import { FacebookShareButton, LineShareButton } from "react-share";
import { PRODUCTION_DOMAIN, PROTOCOL } from "../../../../config";
import { copyText } from "../../../../plugins/common";
import styles from "./style.module.scss";

const ActionArea = ({ getImage = () => {} }) => {
  const path = `${PROTOCOL}://${PRODUCTION_DOMAIN}${window.location.pathname}`;
  const ACTION_BUTTONS = [
    {
      image: "/images/facebook.png",
      name: "Facebook",
      onClick: () => {},
      customBtn: (children) => (
        <FacebookShareButton
          url={path}
          className={styles.actionBtn}
          key="facebook"
        >
          {children}
        </FacebookShareButton>
      ),
    },
    {
      image: "/images/line.png",
      name: "LINE",
      onClick: () => {},
      customBtn: (children) => (
        <LineShareButton url={path} className={styles.actionBtn} key="line">
          {children}
        </LineShareButton>
      ),
    },
    {
      image: "/images/copy_link.png",
      name: "複製連結",
      onClick: () => {
        copyText(path);
      },
    },
    {
      image: "/images/photo_catch.png",
      name: "擷取畫面",
      //TODO
      onClick: () => {
        Dialog.alert({
          title: "提示",
          content: "近期更新",
          closeOnMaskClick: true,
        });
      },
    },
    {
      image: "/images/more.png",
      name: "更多",
      onClick: () => {
        Dialog.alert({
          title: "提示",
          content: "近期更新",
          closeOnMaskClick: true,
        });
      },
    },
  ];
  return (
    <div className={styles.actionArea}>
      <div className={styles.container}>
        {ACTION_BUTTONS.map((item, index) => {
          if (item.customBtn) {
            return item.customBtn(
              <>
                <img
                  className={styles.actionBtnImg}
                  src={item.image}
                  alt={item.name}
                />
                <span className={styles.actionBtnName}>{item.name}</span>
              </>
            );
          } else {
            return (
              <div
                key={index}
                className={styles.actionBtn}
                onClick={() => item.onClick()}
              >
                <img
                  className={styles.actionBtnImg}
                  src={item.image}
                  alt={item.name}
                />
                <span className={styles.actionBtnName}>{item.name}</span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default ActionArea;
