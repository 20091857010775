import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Dialog } from "antd-mobile";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { OrderDrawer } from "../../../../component";
import { useLogin } from "../../../../context/LoginContext";
import styles from "./style.module.scss";

const CardSelectArea = ({ cardSelect, handleRemoveCard, galleriesItems }) => {
  const [openOrderDrawer, setOpenOrderDrawer] = useState(false);
  const [showSelectCard, setShowSelectCard] = useState(false);
  const { isLogin } = useLogin();
  const navigate = useNavigate();
  const handleClick = () => {
    if (!isLogin) {
      Dialog.show({
        title: "提示",
        content: "您尚未登入",
        closeOnAction: true,
        closeOnMaskClick: true,
        actions: [
          [
            {
              key: "cancel",
              text: "稍後",
            },
            {
              key: "delete",
              text: "登入",
              bold: true,
              onClick: () => {
                navigate("/login");
              },
            },
          ],
        ],
      });
      return;
    }
    if (cardSelect.length <= 0) {
      Dialog.alert({
        title: "提示",
        content: "組合內無項目",
        closeOnMaskClick: true,
      });
      return;
    }
    setOpenOrderDrawer(true);
  };
  return (
    <>
      <div
        className={`${styles.container} ${
          showSelectCard && styles.showSelectCard
        }`}
      >
        <div className={styles.header}>
          <div className={styles.leftContainer}>
            <div
              className={styles.toggleBtn}
              style={{
                backgroundColor: showSelectCard
                  ? "var(--cardSelectToggle-active)"
                  : "var(--cardSelectToggle)",
              }}
              onClick={() => setShowSelectCard(!showSelectCard)}
            >
              {showSelectCard ? (
                <MinusOutlined
                  style={{ color: "var(--white)", fontSize: "18px" }}
                />
              ) : (
                <PlusOutlined
                  style={{ color: "var(--white)", fontSize: "18px" }}
                />
              )}
            </div>
            <div className={styles.title}>選取組合({cardSelect.length})</div>
          </div>
          <img
            className={styles.rightContainer}
            src={`/images/cart.png`}
            alt="cart"
            onClick={handleClick}
          />
        </div>
        <div
          className={styles.cardArea}
          onClick={() => {
            if (!showSelectCard) setShowSelectCard(true);
          }}
        >
          <div className={styles.cardList}>
            {cardSelect?.map((card) => {
              return (
                <div
                  className={`${styles.card} ${
                    showSelectCard && styles.showSelectCard
                  }`}
                  key={card.card_id}
                  onClick={() => {
                    if (showSelectCard) handleRemoveCard(card.card_id);
                  }}
                >
                  <img
                    className={styles.cardImg}
                    src={card.image}
                    alt={card.card_name}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <OrderDrawer
        openOrderDrawer={openOrderDrawer}
        setOpenOrderDrawer={setOpenOrderDrawer}
        items={cardSelect}
        galleriesItems={galleriesItems}
      />
    </>
  );
};

export default CardSelectArea;
