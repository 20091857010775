import { LogoutOutlined, RightOutlined } from "@ant-design/icons";
import { Divider, Drawer } from "antd";
import { Dialog } from "antd-mobile";
import { userData } from "../../../../plugins/localStorage";
import { logout } from "../../../../plugins/logout";
import styles from "./style.module.scss";
import "./style.scss";

const InfoDrawer = ({ open, onClose, onClickItem }) => {
  const items = [{ title: "個人資料" }, { title: "訂單" }];
  const handleClickLogout = () => {
    logout();
  };
  return (
    <Drawer
      open={open}
      extra={null}
      closeIcon={null}
      width="300px"
      className="info-drawer"
      onClose={onClose}
    >
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div className={styles.header}>
            <div
              className={styles.iconContainer}
              onClick={() => {
                Dialog.alert({
                  title: "提示",
                  content: "近期更新",
                  closeOnMaskClick: true,
                });
              }}
            >
              <img
                className={styles.notice}
                src={`/images/notification.png`}
                alt="notice"
              />
            </div>
          </div>
          <div className={styles.info}>
            <img
              className={styles.avatar}
              src={userData().avatar ? userData().avatar : `/images/avatar.png`}
              alt="avatar"
            />
            <div className={styles.name}>
              {userData().nickname ? userData().nickname : ""}
            </div>
            <div className={styles.userId}>
              ID<span className={styles.userIdValue}>{userData().userId}</span>
            </div>
          </div>
        </div>
        <div className={styles.bottomContainer}>
          {items.map((item, index) => {
            return (
              <div
                className={styles.itemContainer}
                key={index}
                onClick={() => onClickItem(item)}
              >
                <div className={styles.itemArea}>
                  <div className={styles.item}>
                    <div className={styles.title}>{item.title}</div>
                    <RightOutlined style={{ fontSize: "16px" }} />
                  </div>
                </div>
                <Divider style={{ margin: "16px 0", width: "100%" }} />
              </div>
            );
          })}
        </div>
        <div className={styles.logoutContainer} onClick={handleClickLogout}>
          <div className={styles.item}>
            <div className={styles.title}>登出</div>
            <LogoutOutlined
              style={{ fontSize: "20px", color: "var(--danger6)" }}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default InfoDrawer;
