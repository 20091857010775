import {
  CaretRightOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Form, Input, InputNumber, Radio } from "antd";
import {
  energyItems,
  energyList,
} from "../../pages/Home/enumeration/energyItemsEnum";
import { gameMarkItems } from "../../pages/Home/enumeration/gameMarkItemsEnum";
import { typeItems } from "../../pages/Home/enumeration/typeItemsEnum";
import styles from "./style.module.scss";
import "./style.scss";

const Step0 = ({ items, galleriesItems, form, orderData }) => {
  const findEnum = (targetName, targetEnum, type = "") => {
    if (type === "energy") {
      const target = energyList.filter((item) => item.name === targetName);
      return target[0]?.img;
    } else {
      const target = targetEnum.filter((item) => item.value === targetName);
      return target[0]?.displayName;
    }
  };
  const handleClickPlus = (item) => {
    const targetName = "quota_" + item.card_id;
    const currentValue = form?.getFieldValue(targetName);
    form?.setFieldValue(targetName, currentValue + 1);
  };
  const handleClickMinus = (item) => {
    const targetName = "quota_" + item.card_id;
    const currentValue = form?.getFieldValue(targetName);
    if (currentValue <= 1) return;
    form?.setFieldValue(targetName, currentValue - 1);
  };
  return (
    <>
      <div className={`${styles.buyOrSellContainer} buy-sell-container`}>
        <Form.Item
          name="type"
          initialValue={orderData ? (orderData.type === "buy" ? 0 : 1) : 0}
        >
          <Radio.Group>
            <Radio value={0}>收購</Radio>
            <Radio value={1}>出售</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <div className={`${styles.describeContainer} describe-container`}>
        <Form.Item
          name="comment"
          initialValue={orderData ? orderData.pack_comment : ""}
        >
          <Input
            showCount
            maxLength={100}
            placeholder="添加訂單附加說明 (選填)"
          />
        </Form.Item>
      </div>
      <div className={styles.itemContainer}>
        {items &&
          items.map((item, index) => {
            return (
              <div key={index}>
                <div className={styles.item}>
                  <div className={styles.leftContainer}>
                    <img
                      className={styles.image}
                      src={item.image}
                      alt={item.card_name}
                    />
                    <div className={styles.data}>
                      <div className={styles.dataName}>{item.card_name}</div>
                      <div className={styles.dataFilter}>
                        {item.energy_type && (
                          <img
                            className={styles.energy}
                            src={findEnum(
                              item.energy_type,
                              energyItems,
                              "energy"
                            )}
                            alt={item.energy_type}
                          />
                        )}
                        <div className={styles.mark}>
                          {findEnum(item.game_mark, gameMarkItems)}
                        </div>
                        <div className={styles.galleries}>
                          {findEnum(item.galleries_id, galleriesItems)}
                        </div>
                        <CaretRightOutlined
                          style={{
                            fontSize: "8px",
                            color: "var(--text4)",
                            margin: "0 4px",
                          }}
                        />
                        <div className={styles.type}>
                          {findEnum(item.card_type, typeItems)}
                        </div>
                      </div>
                      <div className={`${styles.dataDescribe} data-describe`}>
                        <Form.Item
                          name={`comment_${item.card_id}`}
                          initialValue={item.comment ? item.comment : ""}
                        >
                          <Input
                            placeholder="添加備註"
                            maxLength={5}
                            showCount
                          />
                        </Form.Item>
                      </div>
                      <div className={`${styles.dataPrice} data-price`}>
                        <div className={styles.currency}>$</div>
                        <Form.Item
                          name={`price_${item.card_id}`}
                          initialValue={item.amount ? Number(item.amount) : 0}
                        >
                          <InputNumber
                            formatter={(value) => (!value ? 0 : value)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={styles.rightContainer}>
                    <div
                      className={styles.minus}
                      onClick={() => handleClickMinus(item)}
                    >
                      <MinusOutlined style={{ fontSize: "16px" }} />
                    </div>
                    <div className={`${styles.quota} quota`}>
                      <Form.Item
                        name={`quota_${item.card_id}`}
                        initialValue={item.quota ? Number(item.quota) : 1}
                      >
                        <InputNumber
                          formatter={(value) => (!value ? 1 : value)}
                        />
                      </Form.Item>
                    </div>
                    <div
                      className={styles.plus}
                      onClick={() => handleClickPlus(item)}
                    >
                      <PlusOutlined style={{ fontSize: "16px" }} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Step0;
