import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import _ from "lodash";
import { useCallback } from "react";
import { useGetCardHot } from "../../hooks/index.hooks";
import HotRender from "../HotRender";
import styles from "./style.module.scss";

const Search = ({
  setSearchValue,
  localSearchValue,
  setLocalSearchValue,
  openHotRender,
  setOpenHotRender,
  setOpenFilterDrawer,
}) => {
  const { data: hotItems } = useGetCardHot();
  const handleSearch = useCallback(
    _.debounce(
      (val) => {
        setSearchValue((prev) => {
          return { ...prev, card_name: val };
        });
      },
      [1000],
      { leading: false }
    ),
    []
  );
  const onSearchValueChange = (val) => {
    setLocalSearchValue((prev) => {
      return { ...prev, card_name: val };
    });
    handleSearch(val);
  };

  return (
    <div className={styles.searchContainer}>
      <div className={styles.topContainer}>
        <Input
          className={styles.input}
          placeholder="請輸入要搜尋的內容"
          prefix={<SearchOutlined style={{ color: "var(--text3)" }} />}
          variant="filled"
          type={"search"}
          allowClear={true}
          value={localSearchValue.card_name}
          onChange={(e) => onSearchValueChange(e.target.value)}
          onClick={() => setOpenHotRender(true)}
          onFocus={() => setOpenHotRender(true)}
          onPressEnter={() => setOpenHotRender(false)}
        />
        <div
          className={styles.button}
          onClick={() => {
            openHotRender ? setOpenHotRender(false) : setOpenFilterDrawer(true);
          }}
        >
          {openHotRender ? "取消" : "篩選"}
        </div>
      </div>
      <div
        className={styles.hotArea}
        style={{ display: openHotRender ? "unset" : "none" }}
      >
        <HotRender
          items={hotItems}
          setSearchValue={setSearchValue}
          setLocalSearchValue={setLocalSearchValue}
          setOpenHotRender={setOpenHotRender}
        />
      </div>
    </div>
  );
};

export default Search;
