import debounce from "lodash/debounce";
import { useCallback, useEffect } from "react";

const INTERSECTION_THRESHOLD = 5;
const LOAD_DELAY = 1000;

export const useLazyLoad = ({
  triggerRef = null,
  fetchNextPage = () => {},
  isFetching = false,
  hasNextPage = true,
  options,
}) => {
  const _handleEntry = async (entry) => {
    const boundingRect = entry.boundingClientRect;
    const intersectionRect = entry.intersectionRect;
    if (hasNextPage && !isFetching) {
      if (
        entry.isIntersecting &&
        intersectionRect.bottom - boundingRect.bottom <= INTERSECTION_THRESHOLD
      ) {
        await fetchNextPage();
      }
    }
  };

  const handleEntry = debounce(_handleEntry, LOAD_DELAY);

  const onIntersect = useCallback(
    (entries) => {
      handleEntry(entries[0]);
    },
    [handleEntry]
  );

  useEffect(() => {
    if (triggerRef.current) {
      const container = triggerRef.current;
      const observer = new IntersectionObserver(onIntersect, options);

      observer.observe(container);

      return () => {
        observer.disconnect();
      };
    }
  }, [triggerRef, onIntersect, options]);
};
