import { Divider } from "antd";
import _ from "lodash";
import { useCallback } from "react";
import styles from "./style.module.scss";

const HotRender = ({
  items,
  setSearchValue = () => {},
  setLocalSearchValue = () => {},
  setOpenHotRender = () => {},
}) => {
  const handleSearch = useCallback(
    _.debounce(
      (val) => {
        setSearchValue((prev) => {
          return { ...prev, card_name: val };
        });
      },
      [1000],
      { leading: false }
    ),
    []
  );
  const handleClick = (item) => {
    setLocalSearchValue((prev) => {
      return {
        ...prev,
        card_name: item.value,
      };
    });
    handleSearch(item.value);
    setOpenHotRender(false);
  };
  return (
    <>
      {items &&
        items.map((item, index) => {
          return (
            <div key={index}>
              <div
                className={styles.container}
                onClick={() => handleClick(item)}
              >
                <div className={styles.container__left}>{item.displayName}</div>
              </div>
              <Divider style={{ margin: 0, color: "var(--line1)" }} />
            </div>
          );
        })}
    </>
  );
};

export default HotRender;
