import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { queryKey } from "../../../enumerations/queryKey";
import {
  apiGetCardHot,
  apiGetCardSearch,
  apiRequestPackOrder,
} from "../service";

export const useGetCardSearch = (options, params) => {
  return useInfiniteQuery({
    queryKey: [queryKey.CARD, params],
    queryFn: ({ pageParam = 1 }) =>
      apiGetCardSearch({ params, page: pageParam }).then((res) => {
        return res.data.content;
      }),
    initialPageParam: 1,
    ...options,
  });
};

export const useGetCardHot = (options) => {
  let count = 0;
  return useQuery({
    queryKey: [queryKey.CARD_HOT],
    queryFn: () =>
      count < 1 &&
      apiGetCardHot().then((res) => {
        count++;
        const data = res.data.data;
        let result = [];
        data.map((item) =>
          result.push({
            displayName: item,
            value: item,
            name: "hot",
          })
        );
        return result;
      }),
    ...options,
  });
};

export const usePackOrder = (options) => {
  return useMutation({
    mutationFn: (params) =>
      apiRequestPackOrder(params).then((res) => {
        return res.data;
      }),
    ...options,
  });
};
