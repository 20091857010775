import { BasicDrawer } from "../../../../component";
import { userData } from "../../../../plugins/localStorage";

const InformationDrawer = ({ open, onClose }) => {
  const items = [
    {
      displayName: "暱稱",
      name: "nickname",
    },
    {
      displayName: "性別",
      name: "gender",
    },
    {
      displayName: "手機",
      name: "phone",
    },
    {
      displayName: "生日",
      name: "birthday",
    },
  ];
  const contentSet = (item) => {
    switch (item.name) {
      case "nickname":
        return userData()?.nickname ? userData()?.nickname : "未設定";
      case "gender":
        return userData()?.gender ? userData()?.gender : "未設定";
      case "phone":
        return userData()?.phone ? userData()?.phone : "未設定";
      case "birthday":
        return userData()?.birthday ? userData()?.birthday : "未設定";
      default:
        break;
    }
  };
  return (
    <>
      <BasicDrawer
        open={open}
        title="個人資訊"
        extra={<></>}
        items={items}
        onClose={onClose}
        showContent={true}
        rightContainer="arrow"
        contentSet={contentSet}
      />
    </>
  );
};

export default InformationDrawer;
