import { LeftOutlined, MoreOutlined } from "@ant-design/icons";
import { Dialog, Popover } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../..";
import { useLogin } from "../../context/LoginContext";
import { useDeleteOrder } from "../../pages/OrderDetail/hooks/index.hooks";
import { userData } from "../../plugins/localStorage";
import styles from "./style.module.scss";
import "./style.scss";

const Header = ({
  toPage = -1,
  title,
  extra,
  orderId,
  orderData,
  setOpenOrderDrawer,
}) => {
  const { isLogin } = useLogin();
  const navigate = useNavigate();
  const userId = userData().userId;
  const { mutate: deleteOrder } = useDeleteOrder();
  const actions =
    userId === orderData?.owner_user_id
      ? [
          {
            key: "copy",
            text: "複製訂單",
            onClick: () => {
              setOpenOrderDrawer(true);
            },
          },
          {
            key: "remove",
            text: (
              <span
                style={{ color: "var(--danger6)" }}
                onClick={() => {
                  Dialog.show({
                    title: "提示",
                    content: "確認移除訂單？",
                    closeOnAction: true,
                    closeOnMaskClick: true,
                    actions: [
                      [
                        {
                          key: "cancel",
                          text: "取消",
                        },
                        {
                          key: "confirm",
                          text: "確認",
                          onClick: () => {
                            deleteOrder(
                              { orderId },
                              {
                                onSuccess: (data) => {
                                  navigate("/order");
                                },
                              }
                            );
                          },
                        },
                      ],
                    ],
                  });
                }}
              >
                移除訂單
              </span>
            ),
          },
        ]
      : [
          {
            key: "copy",
            text: "複製訂單",
            onClick: () => {
              setOpenOrderDrawer(true);
            },
          },
        ];
  return (
    <div className={`${styles.header} header`}>
      <div className={styles.leftContainer}>
        {isLogin && (
          <LeftOutlined
            style={{ fontSize: "16px" }}
            onClick={() => {
              queryClient.clear();
              navigate(toPage);
            }}
          />
        )}
      </div>
      <div className={styles.centerContainer}>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.rightContainer}>
        {isLogin && (
          <Popover.Menu
            actions={actions.map((action) => ({
              ...action,
              icon: null,
            }))}
            placement="bottom-end"
            trigger="click"
            mode="dark"
          >
            {extra ? <MoreOutlined style={{ fontSize: "16px" }} /> : <></>}
          </Popover.Menu>
        )}
      </div>
    </div>
  );
};

export default Header;
