import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //ms time until subsequent request will be triggered again
      staleTime: 3000,
      //when request failed, by default will retry 3x with exponential backoff delay before returning error
      //turn off this option on dev mode to make quicker dev experience
      retry: false,
      //option to refetch data when windows on focus to keep showing latest data to user
      //also turn this off on dev env for simplicity
      refetchOnWindowFocus: false,
    },
  },
});
root.render(
  //<React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
