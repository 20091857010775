import { message } from "antd";

export const copyText = (value) => {
  let oInput = document.createElement("input");
  oInput.value = value;
  document.body.appendChild(oInput);
  oInput.select(); // 选择对象
  document.execCommand("Copy"); // 执行浏览器复制命令
  message.success("複製成功");
  oInput.remove();
};

export const findTotalQuota = (order) => {
  let totalQuota = 0;
  order?.ptcg_pack_detail?.map(
    (perCard) => (totalQuota += Number(perCard.quota))
  );
  return totalQuota;
};
