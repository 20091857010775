import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import localStorageKey from "../enumerations/localStorageKey";

const LoginContext = createContext({
  isLogin: false,
});

export const LoginContextProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();
  const userData = localStorage.getItem(localStorageKey.USER_DATA);
  const token = localStorage.getItem(localStorageKey.TOKEN);

  useEffect(() => {
    if (token && userData) {
      const path = window.location.pathname.split("/")[1];
      if (path === "login" || path === "signup") {
        navigate("/");
      }
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [navigate, token, userData]);
  return (
    <LoginContext.Provider
      value={{
        isLogin,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
export const useLogin = () => {
  const IsLogin = useContext(LoginContext);
  return IsLogin;
};
