import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Checkbox, Divider, Drawer } from "antd";
import styles from "./style.module.scss";
import "./style.scss";

const BasicDrawer = ({
  open,
  setOpen = () => {},
  title,
  placement = "right",
  width = "100%",
  className = "",
  closeIcon = true,
  onClose,
  extra,
  items,
  setItems = () => {},
  rightContainer,
  onCheck,
  searchValue = {},
  setSearchValue = () => {},
  setLocalSearchValue = () => {},
  showContent = false,
  setTitle = () => {},
  getContainer,
  contentSet = () => {},
}) => {
  const handleClick = (item) => {
    if (item.name !== "hot") return;
    setSearchValue((prev) => {
      return {
        ...prev,
        card_name: item.value,
      };
    });
    setLocalSearchValue((prev) => {
      return {
        ...prev,
        card_name: item.value,
      };
    });
    setOpen(false);
  };
  const isChecked = (item) => {
    switch (item.name) {
      case "type":
        return searchValue.card_type.includes(item.value);
      case "energy":
        return searchValue.energy_type.includes(item.value);
      case "galleries":
        return searchValue.galleries_id.includes(item.value);
      case "gameMark":
        return searchValue.game_mark.includes(item.value);
      default:
        return false;
    }
  };
  const rightContainerSet = (item) => {
    switch (rightContainer) {
      case "arrow":
        return (
          <RightOutlined style={{ color: "var(--text2)", fontSize: "14px" }} />
        );
      case "checkbox":
        return (
          <Checkbox
            checked={isChecked(item)}
            onChange={(e) => onCheck(e.target.checked, item)}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Drawer
      className={`basic-drawer ${className}`}
      title={title}
      placement={placement}
      width={width}
      onClose={onClose}
      open={open}
      extra={extra}
      closeIcon={
        closeIcon ? (
          <LeftOutlined style={{ color: "var(--text5)", fontSize: "16px" }} />
        ) : (
          ""
        )
      }
      getContainer={getContainer}
      maskClosable={true}
    >
      {items &&
        items.map((item, index) => {
          return (
            <div key={index}>
              <div
                className={styles.container}
                onClick={() => {
                  setOpen(true);
                  setItems(item.enum);
                  setTitle(item.displayName);
                }}
              >
                <div
                  className={styles.leftContainer}
                  onClick={() => handleClick(item)}
                >
                  {item.displayName}
                </div>
                <div className={styles.rightContainer}>
                  {showContent && (
                    <div className={styles.content}>{contentSet(item)}</div>
                  )}
                  {rightContainerSet(item)}
                </div>
              </div>
              <Divider style={{ margin: 0, color: "var(--line1)" }} />
            </div>
          );
        })}
    </Drawer>
  );
};

export default BasicDrawer;
