import CardDetail from "../CardDetail";
import styles from "./style.module.scss";

const Step1 = ({ items, fieldsValue }) => {
  const findValue = (title, item) => {
    const targetName = title + "_" + item.card_id;
    return fieldsValue[targetName];
  };
  return (
    <div className={styles.formContainer}>
      <CardDetail orderData={fieldsValue} items={items} findValue={findValue} />
    </div>
  );
};

export default Step1;
