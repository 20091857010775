import { useQuery } from "@tanstack/react-query";
import { queryKey } from "../../enumerations/queryKey";
import { apiGetCardSeries } from "./service";

export const useGetCardSeries = (options) => {
  return useQuery({
    queryKey: [queryKey.CARD_SERIES],
    queryFn: () =>
      apiGetCardSeries().then((res) => {
        const data = res.data.data;
        let result = [];
        data.map((item) =>
          result.push({
            displayName: item.galleries_name,
            value: item.galleries_id,
            name: "galleries",
          })
        );
        return result;
      }),
    ...options,
  });
};
