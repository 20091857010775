import { Button } from "antd";
import { useState } from "react";
import { BasicDrawer } from "../../../../component";
import { energyItems } from "../../enumeration/energyItemsEnum";
import { gameMarkItems } from "../../enumeration/gameMarkItemsEnum";
import { typeItems } from "../../enumeration/typeItemsEnum";
import InnerDrawer from "../InnerDrawer";

const FilterDrawer = ({
  openFilterDrawer,
  setOpenFilterDrawer,
  setSearchValue,
  localSearchValue,
  setLocalSearchValue,
  galleriesItems,
}) => {
  const [openInnerDrawer, setOpenInnerDrawer] = useState(false);
  const [drawerItems, setDrawerItems] = useState([]);
  const [title, setTitle] = useState("");

  const items = [
    {
      displayName: "類別",
      enum: typeItems,
      name: "type",
    },
    {
      displayName: "屬性",
      enum: energyItems,
      name: "energy",
    },
    {
      displayName: "系列",
      enum: galleriesItems,
      name: "galleries",
    },
    {
      displayName: "賽事標記",
      enum: gameMarkItems,
      name: "gameMark",
    },
  ];
  const handleClose = () => {
    setOpenFilterDrawer(false);
    setSearchValue(localSearchValue);
  };
  const handleCheck = (checked, item) => {
    const valueSet = (target, targetName) => {
      if (checked) {
        setLocalSearchValue((prev) => {
          target.push(item.value);
          return { ...prev };
        });
      } else {
        setLocalSearchValue((prev) => {
          return {
            ...prev,
            [targetName]: target.filter((_item) => _item !== item.value),
          };
        });
      }
    };
    switch (item.name) {
      case "type":
        valueSet(localSearchValue.card_type, "card_type");
        break;
      case "energy":
        valueSet(localSearchValue.energy_type, "energy_type");
        break;
      case "galleries":
        valueSet(localSearchValue.galleries_id, "galleries_id");
        break;
      case "gameMark":
        valueSet(localSearchValue.game_mark, "game_mark");
        break;
      default:
        break;
    }
  };
  const handleClick = () => {
    setLocalSearchValue((prev) => {
      return {
        ...prev,
        card_type: [],
        energy_type: [],
        galleries_id: [],
        game_mark: [],
      };
    });
  };
  const contentSet = (item) => {
    const findContent = (searchName) => {
      let result = [];
      localSearchValue[searchName].map((value) => {
        const targetIndex = item.enum.findIndex(
          (_item) => _item.value === value
        );
        result.push(
          typeof item.enum[targetIndex].displayName === "string"
            ? item.enum[targetIndex].displayName
            : item.enum[targetIndex].show_name
        );
        return null;
      });
      return result.join(",");
    };
    switch (item.name) {
      case "type":
        return findContent("card_type");
      case "energy":
        return findContent("energy_type");
      case "galleries":
        return findContent("galleries_id");
      case "gameMark":
        return findContent("game_mark");
      default:
        return false;
    }
  };
  return (
    <>
      <BasicDrawer
        open={openFilterDrawer}
        setOpen={setOpenInnerDrawer}
        title="篩選"
        extra={
          <Button
            type="link"
            style={{ padding: 0, fontSize: "16px" }}
            onClick={handleClick}
          >
            重設
          </Button>
        }
        items={items}
        setItems={setDrawerItems}
        onClose={handleClose}
        setTitle={setTitle}
        showContent={true}
        searchValue={localSearchValue}
        rightContainer="arrow"
        contentSet={contentSet}
      />

      <InnerDrawer
        openDrawer={openInnerDrawer}
        setOpenDrawer={setOpenInnerDrawer}
        onCheck={handleCheck}
        drawerItems={drawerItems}
        localSearchValue={localSearchValue}
        title={title}
      />
    </>
  );
};

export default FilterDrawer;
