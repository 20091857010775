import { useMutation, useQuery } from "@tanstack/react-query";
import { queryKey } from "../../../enumerations/queryKey";
import { apiRequestDeleteOrder, apiRequestQueryOrder } from "../service";

export const useGetOrder = (params, options) => {
  return useQuery({
    queryKey: [queryKey.QUERY_ORDER, params],
    queryFn: () =>
      apiRequestQueryOrder(params).then((res) => {
        return res.data;
      }),
    ...options,
  });
};

export const useDeleteOrder = (options) => {
  return useMutation({
    mutationFn: (params) =>
      apiRequestDeleteOrder(params).then((res) => {
        return res.data;
      }),
    ...options,
  });
};
