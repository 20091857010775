import { getCookie, removeCookie, setCookie } from "@analytics/cookie-utils";
import { CloseOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  LoginForm,
  ProFormCheckbox,
  ProFormText,
} from "@ant-design/pro-components";
import { useGoogleLogin } from "@react-oauth/google";
import { Divider, Form, Space, message } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import localStorageKey from "../../enumerations/localStorageKey";
import { remember } from "../../plugins/localStorage";
import { useGoogle, useLogin } from "./hooks/index.hooks";
import styles from "./style.module.scss";
import "./style.scss";

const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate: login } = useLogin();
  const { mutate: googleLogin } = useGoogle();

  const LOGIN_DEVICES = [
    {
      text: "Log In with Google",
      icon: "/images/google.png",
      backgroundColor: "var(--white)",
      onClick: useGoogleLogin({
        onSuccess: (tokenResponse) => {
          const access_token = tokenResponse.access_token;
          handleGoogleLogin(access_token);
        },
        onError: (err) => {
          console.log("Login Failed", err);
        },
      }),
    },
  ];
  const handleSubmit = async () => {
    const params = form.getFieldsValue();
    login(params, {
      onSuccess: (data) => {
        const token = data.token;
        if (params.remember) {
          localStorage.setItem(localStorageKey.REMEMBER, true);
          const nameInfo = params.name;
          setCookie("nameInfo", nameInfo);
        } else {
          localStorage.setItem(localStorageKey.REMEMBER, false);
          removeCookie("nameInfo");
        }
        localStorage.setItem(localStorageKey.TOKEN, token);
        localStorage.setItem(localStorageKey.USER_DATA, JSON.stringify(data));
        message.success("登入成功");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };
  const handleGoogleLogin = async (googleToken) => {
    googleLogin(
      { token: googleToken },
      {
        onSuccess: (data) => {
          const token = data.token;
          localStorage.setItem(localStorageKey.TOKEN, token);
          localStorage.setItem(localStorageKey.USER_DATA, JSON.stringify(data));
          message.success("登入成功");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  };
  useEffect(() => {
    if (remember) {
      form?.setFieldValue("remember", remember);
      const nameInfo = getCookie("nameInfo");
      if (nameInfo) {
        form.setFieldsValue({
          name: nameInfo,
        });
      }
    }
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.close} onClick={() => navigate("/")}>
          <CloseOutlined style={{ color: "var(--white)" }} />
        </div>
        <img
          className={styles.logo}
          src={`/images/login_logo.png`}
          alt="logo"
        />
      </div>
      <div className={`${styles.bottomContainer} bottom-container`}>
        <LoginForm
          title="登入"
          form={form}
          subTitle=" "
          submitter={{
            searchConfig: {
              submitText: "登入",
            },
            submitButtonProps: {
              style: {
                width: "100%",
              },
            },
          }}
          onFinish={() => handleSubmit(false)}
          actions={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Divider plain>
                <span
                  style={{
                    color: "var(--text3)",
                    fontWeight: "normal",
                    fontSize: 14,
                  }}
                >
                  直接使用以下方式登入
                </span>
              </Divider>
              <Space
                direction="vertical"
                style={{ width: "100%" }}
                size="middle"
              >
                {LOGIN_DEVICES.map((item, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: 40,
                        width: "100%",
                        padding: "10px 15px",
                        border: "1px solid " + "var(--text2)",
                        borderRadius: "10px",
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "var(--text3)",
                        backgroundColor: item.icon,
                      }}
                      key={index}
                      onClick={item.onClick}
                    >
                      <img
                        src={item.icon}
                        alt="google"
                        style={{
                          marginRight: "15px",
                          width: "24px",
                          height: "24px",
                        }}
                      />
                      <div style={{ flexGrow: 1, textAlign: "center" }}>
                        {item.text}
                      </div>
                    </div>
                  );
                })}
              </Space>
            </div>
          }
        >
          <>
            <ProFormText
              name="name"
              fieldProps={{
                size: "large",
                prefix: <UserOutlined className={"prefixIcon"} />,
              }}
              placeholder={"請輸入帳號"}
              rules={[
                {
                  required: true,
                  message: "帳號為必填",
                },
              ]}
            />
            <ProFormText.Password
              name="password"
              fieldProps={{
                size: "large",
                prefix: <LockOutlined className={"prefixIcon"} />,
              }}
              placeholder={"請輸入6~12位密碼"}
              rules={[
                {
                  required: true,
                  message: "密碼為必填",
                },
                {
                  min: 6,
                  max: 12,
                  message: "請輸入6~12位密碼",
                },
              ]}
            />
          </>
          <div
            style={{
              marginBlockEnd: 24,
            }}
          >
            <ProFormCheckbox
              noStyle
              name="remember"
              fieldProps={{
                defaultChecked: remember,
              }}
            >
              記住帳密
            </ProFormCheckbox>
            <div
              style={{
                float: "right",
                color: "var(--brand6)",
              }}
              onClick={() => navigate("/signUp")}
            >
              註冊
            </div>
          </div>
        </LoginForm>
      </div>
    </div>
  );
};

export default Login;
