import { useQuery } from "@tanstack/react-query";
import { queryKey } from "../../../enumerations/queryKey";
import { apiRequestOrderList } from "../service";

export const useGetOrderList = (params, options) => {
  return useQuery({
    queryKey: [queryKey.ORDER_LIST],
    queryFn: () =>
      apiRequestOrderList(params).then((res) => {
        return res.data;
      }),
    ...options,
  });
};
